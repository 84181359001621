export default function Website() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.314 30.986a3.386 3.386 0 01-3.381-3.381V5.207c0-.099.005-.197.014-.293A3.384 3.384 0 002.859 8.28v22.398a3.386 3.386 0 003.381 3.38h21.785v-3.073H9.315z"
        fill="#D6F5E5"
      />
      <path
        d="M37.745 8.313v12.294l-.48-.255a.717.717 0 00-.68.004l-.274.15v-7.24H2.91V30.71a3.385 3.385 0 003.38 3.381h21.531c.085.484.198.964.338 1.434H6.291a4.82 4.82 0 01-4.815-4.815V8.313a4.82 4.82 0 014.815-4.816H32.93a4.821 4.821 0 014.815 4.816zM36.31 11.83V8.313a3.386 3.386 0 00-3.381-3.381H6.29a3.384 3.384 0 00-3.38 3.38v3.519h33.4z"
        fill="#35D07F"
      />
      <path
        d="M35.13 35.024a.718.718 0 00.998.09l5.41-4.4a.718.718 0 00-.906-1.113l-4.865 3.959-1.868-2.188a.718.718 0 00-1.091.932l2.322 2.72z"
        fill="#FBCC5C"
      />
      <path
        d="M28.16 35.525a13.022 13.022 0 007.26 8.215l1.192.52a.713.713 0 00.57 0l1.343-.579a12.979 12.979 0 007.951-11.966v-6.024a.716.716 0 00-.379-.633l-8.352-4.45-.48-.256a.717.717 0 00-.68.004l-.274.15-8.313 4.556a.718.718 0 00-.373.629v6.147a12.947 12.947 0 00.535 3.687zm.899-9.409l7.875-4.315 8.108 4.32v5.594a11.549 11.549 0 01-7.079 10.647c-.002 0-.003 0-.005.002l-1.06.457-.904-.395a11.559 11.559 0 01-6.935-10.588v-5.722z"
        fill="#FBCC5C"
      />
      <path
        d="M17.536 10.438h.007a2.004 2.004 0 10.006-4.01h-.006a2.005 2.005 0 00-.007 4.01zm-.395-2.41a.571.571 0 01.402-.165h.001a.57.57 0 010 1.14h-.003a.57.57 0 01-.4-.974zM11.754 10.42h.007a2.005 2.005 0 10.006-4.01h-.007a2.006 2.006 0 00-.006 4.01zm-.396-2.41a.568.568 0 01.402-.166h.002a.571.571 0 11-.404.167zM5.971 10.402h.006a2.005 2.005 0 00.006-4.009h-.006a2.004 2.004 0 10-.006 4.009zm-.395-2.409a.564.564 0 01.4-.166h.003a.57.57 0 11-.002 1.14h-.002a.57.57 0 01-.4-.974z"
        fill="#35D07F"
      />
    </svg>
  );
}
