export default function PiggyBank() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.565 15.866l.874-1.37a14.836 14.836 0 017.31-5.93.613.613 0 00.426-.522 10.74 10.74 0 00-.133-3.321 6.365 6.365 0 00-2.284-.77c.367 1.33.474 2.72.315 4.091a.627.627 0 01-.426.522 14.837 14.837 0 00-7.31 5.93l-.874 1.37a.612.612 0 01-.533.272h2.102a.612.612 0 00.533-.272zM11.437 35.208c.01-2.68-.977-5.269-2.768-7.263a12.05 12.05 0 01-1.986-2.939.621.621 0 00-.57-.352H4.01a.621.621 0 01.57.352 12.05 12.05 0 001.987 2.939 10.812 10.812 0 012.768 7.263c0 .61.492 1.106 1.102 1.112h2.102a1.111 1.111 0 01-1.102-1.112zM3.246 24.185v-7.46c0-.3.21-.587.51-.587H1.654c-.3 0-.51.286-.51.587v7.46c0 .3.21.57.51.57h2.102c-.3 0-.51-.27-.51-.57z"
        fill="#D6F5E5"
      />
      <path
        d="M12.76 14.71a1.36 1.36 0 11-2.721 0 .604.604 0 10-1.209 0 2.57 2.57 0 105.14 0 .605.605 0 00-1.21 0z"
        fill="#35D07F"
      />
      <path
        d="M10.4 37.199h2.37a2.311 2.311 0 002.31-2.309c0-.607.492-1.1 1.1-1.1h4.424c.608 0 1.1.493 1.1 1.1a2.312 2.312 0 002.31 2.309h1.58a.605.605 0 000-1.209h-1.58c-.608 0-1.1-.493-1.1-1.1a2.312 2.312 0 00-2.31-2.309H16.18a2.311 2.311 0 00-2.309 2.31c0 .606-.493 1.098-1.1 1.1H10.4c-.608-.002-1.1-.494-1.101-1.1.01-2.633-.96-5.173-2.72-7.13a11.947 11.947 0 01-1.956-2.904.604.604 0 00-.546-.347H1.76a.552.552 0 01-.551-.552v-7.34c0-.304.247-.55.551-.55H4c.207 0 .399-.107.51-.282l.86-1.358a14.62 14.62 0 017.199-5.845.605.605 0 00.408-.504c.157-1.355.05-2.729-.315-4.043a6.342 6.342 0 015.501 6.277.604.604 0 101.21 0c0-1.05-.22-2.09-.647-3.05a18.162 18.162 0 012.302-.147c8.152 0 14.784 5.981 14.784 13.334 0 .762-.072 1.523-.214 2.272a.604.604 0 101.187.227 13.383 13.383 0 00.227-2.966 3.954 3.954 0 003.48-3.92.604.604 0 10-1.208 0 2.744 2.744 0 01-2.37 2.715C36 11.543 29.224 5.908 21.03 5.908c-.98 0-1.958.075-2.926.224a7.538 7.538 0 00-6.271-3.359.604.604 0 00-.578.782l.17.555c.346 1.122.476 2.3.387 3.47a15.847 15.847 0 00-7.463 6.201l-.682 1.077H1.76c-.971.001-1.759.79-1.76 1.76v7.34c.001.971.789 1.759 1.76 1.76h1.941a13.274 13.274 0 001.983 2.853 9.4 9.4 0 012.407 6.319 2.311 2.311 0 002.31 2.309z"
        fill="#35D07F"
      />
      <path
        d="M36.316 25.147a8.519 8.519 0 108.518 8.519 8.528 8.528 0 00-8.518-8.519zm0 15.829a7.31 7.31 0 117.31-7.31 7.318 7.318 0 01-7.31 7.31z"
        fill="#FBCC5C"
      />
      <path
        d="M44.617 25.452a.52.52 0 00-.04-.049.522.522 0 00-.048-.039c-4.547-4.51-11.88-4.51-16.428 0a.56.56 0 00-.048.04.525.525 0 00-.04.048c-4.51 4.547-4.51 11.88 0 16.428a.527.527 0 00.04.048.541.541 0 00.048.039c4.547 4.511 11.882 4.511 16.428 0a.506.506 0 00.049-.04.523.523 0 00.039-.047c4.51-4.547 4.51-11.881 0-16.428zm1.627 8.818h.53a10.433 10.433 0 01-2.64 6.36l-.37-.371a.605.605 0 00-.855.855l.37.37a10.431 10.431 0 01-6.36 2.64v-.53a.604.604 0 10-1.208 0v.53a10.432 10.432 0 01-6.36-2.64l.371-.37a.604.604 0 10-.855-.855l-.37.37a10.436 10.436 0 01-2.64-6.359h.53a.605.605 0 000-1.209h-.53a10.434 10.434 0 012.64-6.36l.37.371a.604.604 0 10.855-.855l-.37-.37a10.434 10.434 0 016.36-2.639v.529a.605.605 0 001.208 0v-.529c2.357.135 4.6 1.065 6.36 2.639l-.371.37a.605.605 0 00.854.855l.371-.37a10.435 10.435 0 012.64 6.36h-.53a.604.604 0 000 1.208z"
        fill="#FBCC5C"
      />
    </svg>
  );
}
