export default function Exchange() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.996 23.79a6.61 6.61 0 015.626-6.529 6.6 6.6 0 100 13.057 6.61 6.61 0 01-5.626-6.528z"
        fill="#FDEABD"
      />
      <path
        d="M32.704 23.79a6.61 6.61 0 015.625-6.529 6.6 6.6 0 100 13.057 6.61 6.61 0 01-5.625-6.528z"
        fill="#D6F5E5"
      />
      <path
        d="M37.354 16.018a7.772 7.772 0 107.772 7.772 7.78 7.78 0 00-7.772-7.772zm0 14.373a6.601 6.601 0 116.6-6.601 6.608 6.608 0 01-6.6 6.6z"
        fill="#35D07F"
      />
      <path
        d="M44.917 16.304c-.012-.014-.022-.03-.035-.042-.013-.014-.029-.024-.043-.035-4.143-4.111-10.827-4.111-14.97 0-.015.011-.03.021-.043.035-.013.013-.023.028-.035.042-4.111 4.143-4.111 10.828 0 14.97.012.015.022.03.035.043.013.014.028.024.042.035 4.144 4.112 10.828 4.112 14.971 0 .014-.011.03-.021.043-.035.013-.013.023-.028.035-.042 4.111-4.143 4.111-10.828 0-14.97zm1.453 8.071h.44a9.436 9.436 0 01-2.358 5.684l-.31-.308a.585.585 0 00-.827.827l.31.31a9.438 9.438 0 01-5.686 2.359v-.442a.585.585 0 10-1.17 0v.442a9.438 9.438 0 01-5.685-2.36l.309-.309a.586.586 0 00-.828-.827l-.31.308a9.436 9.436 0 01-2.358-5.684h.441a.586.586 0 000-1.17h-.441a9.435 9.435 0 012.359-5.685l.309.308a.584.584 0 10.828-.827l-.31-.31a9.438 9.438 0 015.686-2.359v.442a.586.586 0 001.17 0v-.442c2.105.13 4.107.96 5.685 2.36l-.309.309a.585.585 0 10.828.827l.309-.308a9.435 9.435 0 012.359 5.684h-.441a.585.585 0 100 1.171z"
        fill="#35D07F"
      />
      <path
        d="M10.646 16.018a7.772 7.772 0 107.772 7.772 7.78 7.78 0 00-7.772-7.772zm0 14.373a6.601 6.601 0 116.601-6.601 6.608 6.608 0 01-6.6 6.6z"
        fill="#FBCC5C"
      />
      <path
        d="M18.21 16.304c-.013-.014-.023-.03-.036-.042-.013-.014-.028-.024-.042-.035-4.144-4.111-10.828-4.111-14.971 0-.014.011-.03.021-.042.035-.014.013-.024.028-.036.042-4.11 4.143-4.11 10.828 0 14.97.012.015.022.03.036.043.012.014.028.024.042.035 4.143 4.112 10.827 4.112 14.97 0 .015-.011.03-.021.043-.035.014-.013.023-.028.035-.042 4.111-4.143 4.111-10.828 0-14.97zm1.452 8.071h.441a9.436 9.436 0 01-2.359 5.684l-.309-.308a.585.585 0 10-.827.827l.308.31a9.438 9.438 0 01-5.684 2.359v-.442a.585.585 0 10-1.171 0v.442a9.438 9.438 0 01-5.685-2.36l.31-.309a.586.586 0 10-.829-.827l-.309.308a9.436 9.436 0 01-2.359-5.684h.442a.586.586 0 000-1.17h-.442a9.436 9.436 0 012.36-5.685l.308.308a.584.584 0 10.828-.827l-.309-.31a9.438 9.438 0 015.685-2.359v.442a.586.586 0 001.17 0v-.442c2.106.13 4.107.96 5.685 2.36l-.308.309a.585.585 0 10.827.827l.31-.308a9.435 9.435 0 012.358 5.684h-.441a.585.585 0 100 1.171z"
        fill="#FBCC5C"
      />
      <path
        d="M26.066 4.976a13.934 13.934 0 00-8.837 3.154l-2.086-2.086a.585.585 0 00-.999.414v4.894c0 .323.262.585.585.585h4.894a.585.585 0 00.414-1L18.06 8.963a12.787 12.787 0 0118.486 2.645.585.585 0 10.958-.672 13.98 13.98 0 00-11.44-5.96zm-10.751 5.79V7.871l1.44 1.441.003.003a.558.558 0 00.05.05l1.402 1.401h-2.895zM37.017 36.032h-4.893a.585.585 0 00-.414 1l1.976 1.975A12.787 12.787 0 0115.2 36.362a.585.585 0 00-.96.672 13.958 13.958 0 0020.276 2.805l2.087 2.086a.585.585 0 00.999-.414v-4.894a.586.586 0 00-.586-.585zm-.585 4.066l-1.44-1.441-.003-.003a.511.511 0 00-.05-.05l-1.402-1.401h2.895v2.895z"
        fill="#35D07F"
      />
    </svg>
  );
}
